/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';
import '../css/global.scss';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
import $ from 'jquery'

window.$ = $;
require('bootstrap');
require('datatables.net');
require('datatables.net-bs4');
require('select2');
require('jstree');

import 'select2/dist/css/select2.css';
import Chart from 'chart.js';
import moment from 'moment';
import collection from 'symfony-collection/jquery.collection.js';

console.log('app.js loaded');
$(document).ready(function () {
    $('.select2_box').select2({
        theme: "classic",
        width: "100%"
    });
});
/**
 * AJAX Loader
 */
$(document).ajaxStart(function () {
    $('.ajax-loader-modal').toggle();
});

$(document).ajaxStop(function () {
    $('.ajax-loader-modal').toggle();
});

